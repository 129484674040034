import "./banner.css";

const Banner = () => {
	return (
		<section className="banner">
			<h2 className="slogan">Keep track of your job interviews...</h2>
		</section>
	);
};
export default Banner;
